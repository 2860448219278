import React from "react";
import { cutString, formatTimeLeft, truncateText } from "../utils";
import { CiClock2 } from "react-icons/ci";

export const WaitingEvent = ({ event }) => {
  return (
    <div>
      <div className="card meeting-card rounded-lg">
        <img
          className="h-32 sm:h-40 md:h-48 lg:h-56 w-full object-fill  rounded-t-lg"
          src={event.flyer}
          alt={event.title}
        />
        <div className="card-body">
          <h5 className="card-title">
            <b>{cutString(event.title, 20)}</b>
          </h5>
          <div
            dangerouslySetInnerHTML={{
              __html: truncateText(event.description),
            }}
          />
          <div className="bg-white rounded-lg shadow-md p-3 mt-1">
            <div className="flex items-center space-x-2">
              <CiClock2 size={25} />
              <span className="text-gray-600 text-sm">
                {new Date(event.event_time).toLocaleString()}
              </span>
              <div className="bg-blue-100 text-blue-800 text-xs font-semibold px-2 py-1 rounded-full">
                {formatTimeLeft(event.event_time)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
